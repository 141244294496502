import React, {useState} from 'react';
import Translator from './i18n/Translator';
import Auth from './signin/Auth';
import { Link } from 'react-router-dom';

const NotFound = () => {
const [openModal, setOpenModal] = useState(false);

    return (
        <div className="not-found-page content">
            <h4>It is not possible to access the page you were looking for.</h4>
            <h4>For Yoga Club pages, please try logging in below, or go to the home page.</h4>
            <button className="btn btn-success" onClick={() => setOpenModal(true)}><Translator path="Login"/></button>
            <Link to="/" className="btn btn-primary" ><Translator path="Home"/></Link>
            <Auth closeModal={() => setOpenModal(false)} openModal={openModal}/>


        </div>
    )
}

export default NotFound;