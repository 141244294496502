import React, { useState, useEffect } from "react";
import "../styles/stylesheet.css";
import { Row, Col } from "react-bootstrap";
import { getKartak, getHomepageText } from '../contentful'
import Slider from "./Slider";
import Translator from "./i18n/Translator";
import marked from 'marked';
import maite from '../assets/imgs/Maite1.jpg'



const Main = () => {
 

  const [slider, setSlider] = useState([]);
  const [text, setText] = useState([])

  useEffect(() => {
    window.scrollTo(0,0)
    getKartak().then((data) => setSlider(data));
    getHomepageText().then(data => setText(data))
    

  },[])
 
     
    return (
      <div>
      
        <div >
          <div className="hero-text">
          <h1>Maite Arrese</h1>
          <h3><Translator path="Subtitle"/></h3>
          </div>
        </div>
<div style={{margin:'20px'}}>
        <Row>
          <Col md={6}>
          {/* <Slider cards={slider} /> */}
          <img src={maite} style={{width:'100%'}}/>
          </Col>
          <Col md={6}>     {text.map(text => {
let content;
if(!window.localStorage.lang){content = marked(text.fields.contentEs)}
if(window.localStorage.lang === 'es-ES'){content = marked(text.fields.contentEs)}
if(window.localStorage.lang === 'eu-EU'){content = marked(text.fields.contentEu)}
return <div style={{textAlign:'justify'}} dangerouslySetInnerHTML={{__html: content }} />

          }
            )}  
</Col>
        </Row>
        </div>
      </div>
    );
  }


export default Main;
