import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyAQpbGwtrpMcRbaGcDAXaTwePgfLgKwkPA",
    authDomain: "maitearrese-787d6.firebaseapp.com",
    databaseURL: "https://maitearrese-787d6.firebaseio.com",
    projectId: "maitearrese-787d6",
    storageBucket: "maitearrese-787d6.appspot.com",
    messagingSenderId: "7158838722",
    appId: "1:7158838722:web:b2a4a447f0b6a19fd3925b",
    measurementId: "G-Y4K4PPZ0JR"}

const fire = firebase.initializeApp(config);
export default fire;