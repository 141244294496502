import React from "react";
import { useTranslation } from "react-i18next";
// useTranslation is a hook
// that returns a translation function (t) and the i18n instance


const I18n = () => {
  const { i18n } = useTranslation();
  // i18n instance

  function handleChangeLanguage(language) {
    // changing the language when this function is called
    i18n.changeLanguage(language);
    window.localStorage.setItem("lang", language);

    window.location.reload();
  }

  const selectedLanguage = i18n.language;
  return (
    <div className="flags">
      <h6
        className="flag"
        onClick={() => handleChangeLanguage("eu-EU")} // changes the language to pt-BR
      >
        EU
      </h6>
      <h6
        className="flag"
        
        onClick={() => handleChangeLanguage("es-ES")} // changes the language to en-US
      >
        ES
      </h6>
      {/* <h6
        className="flag"
        onClick={() => handleChangeLanguage("en-US")} // changes the language to en-US
      >
        EN
      </h6> */}
    </div>
  );
};

export default I18n;
