import React, {useState, useEffect} from 'react';
import Auth from '../signin/Auth';
import Translator from '../i18n/Translator';
import { Link } from 'react-router-dom';
import {getYogaClubPage} from '../../contentful';
import TitleBar  from '../TitleBar';
import marked from 'marked';
import bizum from "../../assets/imgs/bizum.svg";


const YogaClub = () => {
    const [openModal, setOpenModal] = useState(false)
    const [content, setContent] = useState([])

    useEffect(() => {
        getYogaClubPage().then(data => setContent(data))
    })
    return(

        <div className="content">
      {content.map(content => {
          let text = marked(content.fields.textEs)
          return(
              <div>
                  <TitleBar title={content.fields.title}/>
<div dangerouslySetInnerHTML={{__html: text}} />
<div className="text-center" style={{marginBottom:10}} >
<Link className="btn btn-warning" to="/contact"><Translator path="ContactUs"/></Link><br/>
<img src={bizum} style={{width:'60px'}}/>
</div>

<img src={content.fields.mainImage.fields.file.url} style={{width:'100%'}}/>

              </div>
          )
      })}
      
        </div>
    )
}

export default YogaClub;