import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getSinglePost } from "../contentful";
import { Row, Col } from "react-bootstrap";
import marked from "marked";
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  FacebookShareButton,
} from "react-share";

const SinglePost = (props) => {
  const [post, setPost] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getSinglePost(id).then((data) => setPost(data));
  }, []);

  return (
    <div className="content">
      {post.map((post) => {
        let desc;
        if (post.fields.description) {
          desc = marked(post.fields.description);
        }
        let video;
        if (post.fields.youTubeLink) {
          video = marked(post.fields.youTubeLink);
        }
        let content;
        if (post.fields.mainText) {
          content = marked(post.fields.mainText);
        }
        return (
          <div>
            <Row>
             {post.fields.title && <Col md={6}>
                <h3>{post.fields.title}</h3>
                {post.fields.description && (
                  <section
                    style={{ fontStyle: "italic" }}
                    dangerouslySetInnerHTML={{ __html: desc }}
                  />
                )}
              </Col>}
              <Col md={6}>
                {post.fields.mainImage && (
                  <img
                    style={{ width: "100%" }}
                    src={post.fields.mainImage.fields.file.url}
                  />
                )}
              </Col>
            </Row>
            {post.fields.youTubeLink && (
              <div
                style={{ marginTop: "20px", textAlign: "center" }}
                dangerouslySetInnerHTML={{ __html: video }}
              />
            )}
            {post.fields.mainText && (
              <div dangerouslySetInnerHTML={{ __html: content }} />
            )}
            <Row>
            {post.fields.imageGallery && post.fields.imageGallery.map(image => 
             <Col md={3}> <img style={{width:'100%'}} src={image.fields.file.url}/>
              </Col>
              )}
              </Row>
          </div>
        );
      })}

 <div className="sharing-buttons">
            <small>Share this page on your social media</small><br></br>
              <TwitterShareButton
                url={window.location}
                children={<TwitterIcon size={24} />}
              />
              <LinkedinShareButton
                url={window.location}
                children={<LinkedinIcon size={24} />}
              />
              <FacebookShareButton
                url={window.location}
                children={<FacebookIcon size={24} />}
              />

            </div> 

    </div>
  );
};

export default SinglePost;
