export default {
    translations: {
        
        About: 'Sobre mi',
        Stories: 'Cuentacuentos',
        Schools: 'Clases',
        Cards: 'Tienda',
        Workshops: 'Talleres',
        Meditation: 'Meditación',
        Blog: 'Blog',
        Contact: 'Contacto',
        Subtitle: 'Yoga,  Cuentos y Meditación',

        Login: 'YOGA CLUB: iniciar sesión',
        BuyHere:'Haga clic aquí y cómpralas Ahoramismo',
        IncludingShipping: 'inc. gastos',
        FormText1: 'Utilice este formulario para realizar su pedido.',
        FormText2: 'Puede pagar directamente a través de Bizum, o si lo prefiere por transferencia bancaria. ',
        FormText3: 'En el caso de que desee pagar mediante transferencia bancaria, recibirá los datos bancarios por correo electrónico.',
        Name: 'Nombre y Apellido(s)',
        Email: 'Correo-electronico',
        Address: 'Dirección postal',
        BizumPayment: 'He pagado ahoramismo por Bizum à 0690 630 748',
        BankPayment: 'Me gustaría pagar por transferencia. Envíame, por favor datos bancarios por Email.',
        Submit: 'Enviar',
        Close: 'Cerrar',
        ReadMore: 'leer más',
        SignUp:' Regístrate',
        Classes1: 'HORARIO',
        Classes2: 'Imparto las clases de yoga en Donostia-San Sebastián. Calle Tejeria, 28. EGIA',
        ContactUs: 'escríbenos',
        Share: 'compartir esta página en tus redes sociales',
        Login: 'Yoga Klub: iniciar sesión',
        YogaClub:'Yoga Klub',
        Back: 'atrás',
        SignInError: 'el correo electrónico o la contraseña no existen. Inténtalo de nuevo.',
        Password:'contraseña',
        MembersArea: 'Yoga Klub: Miembros ',
        YogaClubVideos: 'Yoga Klub: Videos',

                
                }
          
    
}