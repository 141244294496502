import React, { useState, useEffect } from "react";
import TitleBar from "./TitleBar";
import marked from "marked";
import { getCuentosMusicados, getCuentos } from "../contentful";
import { Row, Col } from "react-bootstrap";
import Translator from "./i18n/Translator";
import { Link } from 'react-router-dom';
import story from '../assets/imgs/story_small.jpg'

const Cuentos = () => {
  const [cuentos, setCuentos] = useState([]);

  useEffect(() => {
    window.scrollTo(0,0)
    getCuentos().then((data) => setCuentos(data));
  }, []);
  return (
    <div className="content">
      <div style={{marginBottom:25}}>
      <Row >
        <Col md={8}>      <TitleBar title={<Translator path="Stories"/>} />
</Col>
        <Col md={4}>
          <img src={story} style={{width:'100%'}}/>
        </Col>
      </Row>
      </div>
      {cuentos.map((item) => {
        let title;
        if(!window.localStorage.lang){title = item.fields.titleEs}
        if (window.localStorage.lang === "es-ES") {
          title = item.fields.titleEs;
        }
        if (window.localStorage.lang === "eu-EU") {
          title = item.fields.title;
        }
        let sub;
        if(!window.localStorage.lang){sub = item.fields.subtitleEs}
        if (window.localStorage.lang === "es-ES") {
          sub = item.fields.subtitleEs;
        }
        if (window.localStorage.lang === "eu-EU") {
          sub = item.fields.subtitle;
        }

        return (
          <div className="project-card">
            <Row>
              <Col md={6}>
                {" "}
                <img
                  src={item.fields.mainImage.fields.file.url}    
                />
              </Col>
              <Col md={6}>
                {" "}
                <div className="project-card-text">
                <h4 style={{textAlign:'center'}} >{title}</h4>
                {sub && (
                  <p>
                    <em>{sub}</em>
                  </p>
                )}
                <Link to={`/cuentos/${item.sys.id}`} className="btn btn-primary"><Translator path="ReadMore"/></Link>
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default Cuentos;
