import React, {useState, useEffect} from 'react'
import marked from 'marked'
import {Row, Col} from 'react-bootstrap'
import TitleBar from './TitleBar'
import {getWorkshops} from '../contentful'
import { FaRegChartBar } from 'react-icons/fa'
import Translator from './i18n/Translator'
import { Link } from 'react-router-dom'

const Taller = () => {
const [workshops, setWorkshops] = useState([])

useEffect(() => {
    window.scrollTo(0,0)

    getWorkshops().then(data => setWorkshops(data)
    )
}, [])

    return(
        <div className="taller-page" >
            <TitleBar title={<Translator path="Workshops"/>}/>
            {workshops.map(event => {

let date;
if(event.fields.fecha){ date = new Date(event.fields.fecha)}
const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
let text;
if(!window.localStorage.lang){text = marked(event.fields.textEs)}
if(window.localStorage.lang === 'eu-EU'){text = marked(event.fields.textEu)}
if(window.localStorage.lang === 'es-ES'){text = marked(event.fields.textEs)}
let title;
if(!window.localStorage.lang){title = event.fields.titleEs}
if(window.localStorage.lang === 'eu-EU'){title = event.fields.title}
if(window.localStorage.lang === 'es-ES'){title = event.fields.titleEs}

return <div className="taller-card">
{event.fields.fecha &&  <h3>{date.toLocaleDateString('es-ES', options)}</h3>}

    <Row>
        <Col md={4}>
        <img src={event.fields.mainImage.fields.file.url} style={{width:'100%'}} />
        
     
        
        </Col>
        <Col>
        <div className="taller-info" >
        <h4>{title}</h4>
        <section dangerouslySetInnerHTML={{__html: text}}  />
        {event.fields.gallery && 
        <Row>
            {event.fields.gallery.map(img => 
            <Col md={4}><img src={img.fields.file.url} style={{width:'100%'}} /></Col>
            )}
        </Row>
            }
        <Link to="/contact" style={{color:'white'}} className="btn btn-warning"><Translator path="ContactUs"/></Link>

        </div>
        </Col>
    </Row>


</div>
})}
</div>
    )
}

export default Taller;