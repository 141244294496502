const client = require('contentful').createClient({
    space: '9uoo9wdiybla',
    accessToken: 'V1PFgbseASimd89CsySwaTgaP48gkgf5Dw73AoO-IzA'
  })
  var gt = new Date().toISOString();

  
  const getPosts = () => client.getEntries({content_type: 'blog'}).then(response => response.items)
  const getSinglePost = (slug) => client.getEntries({content_type: 'blog', 'sys.id': slug}).then(response => response.items)

  const getBiog = () => client.getEntries({content_type: 'biography'}).then(response => response.items)
  const getSliderImages = () => client.getEntries({content_type: 'sliderImage'}).then(response => response.items)
  const getKartak = () => client.getEntries({content_type: 'card'}).then(response => response.items)
  const getKartakPage = () => client.getEntries({content_type: 'kartak'}).then(response => response.items)
  const getMeditationPage = () => client.getEntries({content_type: 'meditationPage'}).then(response => response.items)
  
  const getYogaClubPage = () => client.getEntries({content_type: 'yogaClub'}).then(response => response.items)
  const getYogaClubMembersPage = () => client.getEntries({content_type: 'yogaClubMembersPage'}).then(response => response.items)
  const getClubVideos = () => client.getEntries({content_type: 'clubPosts', order: '-fields.title'}).then(response => response.items)
  const getClubPosts = () => client.getEntries({content_type: 'clubArticles'}).then(response => response.items)
  
  
  const getWorkshops = () => client.getEntries({content_type: 'taller'}).then(response => response.items)
  const getClasses = () => client.getEntries({content_type: 'classDay'}).then(response => response.items)
  
  
  const getCuentos = () => client.getEntries({content_type: 'cuentos'}).then(response => response.items)
  const getSingleCuento = (slug) => client.getEntries({content_type: 'cuentos', 'sys.id': slug}).then(response => response.items)
  


 const getPrivacy = () => client.getEntries({content_type: 'privacy'}).then(response => response.items)
 const getHomepageText = () => client.getEntries({content_type: 'homepageText'}).then(response => response.items)

 




  export {getClubPosts, getYogaClubPage, getYogaClubMembersPage, getMeditationPage, getClasses, getWorkshops, getClubVideos, getSingleCuento, getKartakPage, getCuentos,  getKartak, getPosts, getSinglePost, getSliderImages, getBiog, getHomepageText, }