import React, { useEffect, useState } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaEnvelopeOpen,
  FaPhoneAlt,
  FaWhatsapp,
} from "react-icons/fa";
import Translator from "./i18n/Translator";
import TitleBar from "./TitleBar";
import { Row, Col } from "react-bootstrap";
import maite from "../assets/imgs/characature.jpg";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="contact-page content">
        <TitleBar title={<Translator path="Contact" />} />
        <div style={{width:'30%', margin:'auto'}}>
        <img src={maite} style={{ width: "100%"}} />
        </div>
        <a
                target="_blank"
                href="mailto:maitearreseartabe@gmail.com"
              >
                <div className="card">
                  <FaEnvelopeOpen color="saddlebrown" size={29} />
                  <h6>Email</h6>
                </div>
              </a>
        <Row>
          <Col md={3} sm={6}>
            <div className="contact-buttons">
              <a
                target="_blank"
                href="https://api.whatsapp.com/send?phone=34690630748"
              >
                <div className="card">
                  <FaWhatsapp id="whatsapp" size={29} />
                  <h6>Whatsapp</h6>
                </div>
              </a>
            </div>
          </Col>

          <Col md={3} sm={6}>
            {" "}
            <a target="_blank" href="tel:+34690630748">
              <div className="card">
                <FaPhoneAlt id="phone" size={29} />
                <h6>tel: +34 690 630 748</h6>
              </div>
            </a>
          </Col>
          <Col md={3} sm={6}>
            {" "}
            <a
              target="_blank"
              href="https://www.facebook.com/egiaYoga"
            >
              <div className="card">
                <FaFacebook size={29} />
                <h6>Facebook</h6>
              </div>
            </a>
          </Col>
          <Col md={3} sm={6}>
            {" "}
            <a
              target="_blank"
              href="https://www.instagram.com/egianyoga/"
            >
              <div className="card">
                <FaInstagram size={29} />
                <h6>Instagram</h6>
              </div>
            </a>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Contact;
