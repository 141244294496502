import React, {useState, useEffect} from 'react'
import {getClubVideos} from '../../contentful';
import marked from 'marked';
import {Row, Col} from 'react-bootstrap';
import Translator from '../i18n/Translator';


const ClubMemberPosts = () => {
const [posts, setPosts] = useState([]);

useEffect(() => {
getClubVideos().then(data => setPosts(data))
}, [])


    return( 
    <div className="content">
        <h3><Translator path="YogaClubVideos"/></h3>
{posts.map(info => {
let text;
if(!window.localStorage.lang){text = marked(info.fields.textEu)}
else if(window.localStorage.lang === 'eu-EU'){text = marked(info.fields.textEu)}
else if(window.localStorage.lang === 'es-ES'){text = marked(info.fields.textEs)}

return <div className="club-post">
  
        <div className="club-post-text">
            {info.fields.mainImage &&
            <img src={info.fields.mainImage.fields.file.url} style={{width:'50%', marginBottom:'20px'}}/>
            }
<h4>{info.fields.title}</h4>

<section className="text-center" dangerouslySetInnerHTML={{__html: text}} />
</div>
    
        { info.fields.video &&
    <video style={{width:'50%'}} className="video" controls>
            <source  src={info.fields.video.fields.file.url} type="video/mp4"/>
                Your browser does not support the video tag
        </video> 
}




</div>


})}




    </div>
    
    
        )
}

export default ClubMemberPosts;