import React  from "react";
import { Link } from "react-router-dom";
import {  FaFacebook, FaLinkedin, FaInstagram, FaWhatsapp, FaTimesCircle, FaEnvelopeOpen } from "react-icons/fa";
import { Dropdown, NavLink, NavItem } from "react-bootstrap";
import Translator from "../i18n/Translator";
import I18n from "../i18n/i18n";
import logo from '../../assets/logo.png'

import "./SideDrawer.css";

const sideDrawer = (props) => {
  


  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }
  return (
    <div>
      <nav className={drawerClasses}>
        <div className="close-cross">
      <FaTimesCircle id="cross" size={20} onClick={props.click} />
      </div>
      <div className="drawer-logo">
      <Link onClick={props.click} to="/">
          <img style={{width:'80%'}} src={logo}/>
</Link>
</div>
        <div className="header">
     

          <ul>
           
            <li>
              <Link onClick={props.click} to="/biography">
                <Translator path="About" />
              </Link>
            </li>
            {props.user &&
            <Dropdown as={NavItem}>
                  <Dropdown.Toggle as={NavLink}><Translator path="YogaClub"/></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      {" "}
                      <Link onClick={props.click}  to="/clubmemberspage"><Translator path="MembersArea" /></Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      {" "}
                      <Link onClick={props.click}  to="/clubposts"><Translator path="Videos" /></Link>
                    </Dropdown.Item>       
                  </Dropdown.Menu>
                </Dropdown>
                }
          {  !props.user &&
            <li>
              <Link onClick={props.click}  to="/yogaclub"><Translator path="YogaClub" /></Link>

            </li>
            
}
            <li>
              <Link onClick={props.click} to="/cuentos">
                <Translator path="Stories" />
              </Link>
            </li>
            <li>
              <Link onClick={props.click} to="/classes">
                <Translator path="Schools" />
              </Link>
            </li>
           

          <li>
          <Link onClick={props.click}  to="/cards"><Translator path="Cards" /></Link>
          </li>
          <li>
              <Link onClick={props.click} to="/workshops">
                <Translator path="Workshops" />
              </Link>
            </li>
            <li>
              <Link onClick={props.click} to="/meditation">
                <Translator path="Meditation" />
              </Link>
            </li>
            <li>
              <Link onClick={props.click} to="/blog">
                <Translator path="Blog" />
              </Link>
            </li>
           
            <li>
              <Link onClick={props.click} to="/contact">
                <Translator path="Contact" />
              </Link>
            </li>
          </ul>
          <div className="social">
            {/* <a
              href="https://www.facebook.com/ann.cnop.5"
              target="_blank"
            >
              <FaFacebook size={35} />
            </a> */}
            {/* <a
              href="https://www.linkedin.com/in/ann-cnop-501506105/"
              target="_blank"
            >
              <FaLinkedin size={35} />
            </a> */}
              <a
                target="_blank"
                href="mailto:maitearreseartabe@gmail.com"
              >
                  <FaEnvelopeOpen color="saddlebrown" size={20} />
              </a>
             <a href="https://api.whatsapp.com/send?phone=34690630748" target="_blank">
            <FaWhatsapp color={"green"} size={20} />
          </a>
          <a href="https://www.instagram.com/egianyoga/" target="_blank">
            <FaInstagram color={"purple"} size={20} />
          </a>
          <a href="https://www.facebook.com/egiaYoga" target="_blank">
            <FaFacebook color={"blue"} size={20} />
          </a>
          </div>
         
          <div className="i18n">
            <I18n />
          </div>
          

        </div>

      </nav>
    </div>
  );
};

export default sideDrawer;
