import React from "react";
import { FaSignInAlt, FaInstagram, FaPeace, FaVideo, FaFacebook, FaWhatsapp, FaSignOutAlt, FaFilm, FaEnvelopeOpen } from "react-icons/fa";
import I18n from "../i18n/i18n";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import Translator from '../i18n/Translator'

import "./Toolbar.css";
import DrawerToggleButton from "../DrawerToggleButton/DrawerToggleButton";

const toolbar = (props) => (
  <header className="toolbar">
    <nav className="toolbar__navigation">
      <div className="toolbar__toggle-button">
        <DrawerToggleButton click={props.drawerClickHandler} />
      </div>
      <div className="toolbar__logo">
        <Link to="/">
        <img className="logo" src={logo} alt="" />
        </Link>
      </div>
      <div style={{marginLeft:'30px'}} >
        {!props.user && <Link className="auth-link" onClick={props.signIn}> 
        <span className="toolbar-text" > <Translator path="Login"/> </span> 
        <FaSignInAlt  className="icon"size={20}/></Link>}
        
        {props.user && <> <Link className="auth-link" onClick={props.signOut}> <span className="toolbar-text" ><Translator path="Logout"/></span>   <FaSignOutAlt className="icon" size={20}/></Link>
        <Link className="auth-link" to="/clubmemberspage" > <Translator path="MembersArea"/>  <FaPeace className="icon" size={20}/></Link>
       <Link className="auth-link" to="/clubposts" ><Translator path="Videos"/> <FaFilm className="icon" size={20}/></Link> </>}
        </div>
      <div className="spacer"></div>
      <div className="toolbar_navigation-items">
        <div className="nav-right">
          <I18n />
<div className="desktop-only">
          <a className="toolbar-icon" href="https://api.whatsapp.com/send?phone=34690630748" target="_blank">
            <FaWhatsapp color={"green"} size={20} />
          </a>
          <a className="toolbar-icon"
                target="_blank"
                href="mailto:maitearreseartabe@gmail.com"
              >
                  <FaEnvelopeOpen color="antiquewhite" size={20} />
              </a>
          <a className="toolbar-icon" href="https://www.instagram.com/egianyoga/" target="_blank">
            <FaInstagram color={"purple"} size={20} />
          </a>
          <a className="toolbar-icon" href="https://www.facebook.com/egiaYoga" target="_blank">
            <FaFacebook color={"darkblue"} size={20} />
          </a>
          </div>
        </div>
      </div>
    </nav>
  </header>
);

export default toolbar;
