import React, { useState, useEffect } from "react";
import TitleBar from "./TitleBar";
import Translator from "./i18n/Translator";
import { Row, Col } from "react-bootstrap";
import { getClasses } from "../contentful";
import { Link } from 'react-router-dom'
import class1 from '../assets/imgs/class2.jpg'

const Classes = () => {
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getClasses().then((data) => setClasses(data));
  }, []);

  return (
    <div>
      <TitleBar title={<Translator path="Schools" />} />

      <div className="banner-image"></div>

      <div >
        <Row>
          <Col md={6}>
          <div className="horarios">
            <h4>
              <Translator path="Classes1" />
            </h4>
            <p style={{ textAlign: "center" }}>
              <Translator path="Classes2" />
            </p>

            <Row>
              {classes.map((day) => {
                let dayName;
                if (!window.localStorage.lang) {
                  dayName = day.fields.dayEs;
                }
                if (window.localStorage.lang === "es-ES") {
                  dayName = day.fields.dayEs;
                }
              
               if (window.localStorage.lang === "eu-EU") {
                  dayName = day.fields.day;
                }
                

                return (
                  <Col key={dayName}>
                    <div className="day-card">
                      <h4>{dayName}</h4>
                      <h5>{day.fields.class1}</h5>
                      <h5>{day.fields.class2}</h5>
                    </div>
                  </Col>
                );
              })}
            </Row>
            <Link to="/contact" className="btn btn-warning"><Translator path="ContactUs"/></Link>
            </div>
          </Col>
          <Col md={6}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2902.8509033946334!2d-1.9728294197348193!3d43.31738048220059!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd51a55dd63c65b5%3A0xd83e95b52e716bd2!2sTejeria%20Kalea%2C%2028%2C%2020012%20Donostia%2C%20Gipuzkoa!5e0!3m2!1sen!2ses!4v1606319990937!5m2!1sen!2ses"
              width="600"
              height="450"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </Col>
        </Row>
      </div>
      <div >
        <img style={{width:'100%'}} src={class1} />
      </div>
    </div>
  );
};

export default Classes;
