import React, { useState, useEffect } from "react";
import Translator from "./i18n/Translator";
import { Row, Col } from "react-bootstrap";
import { getBiog } from "../contentful";
import TitleBar from "./TitleBar";
import marked from "marked";


const Biog = () => {
  const [text, setText] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0)
   getBiog().then((data) => setText(data)
    )}, []);

  return (
    <div>
{text.map(info => {
let text;
if(!window.localStorage.lang){text = marked(info.fields.textEs)}
else if(window.localStorage.lang === 'eu-EU'){text = marked(info.fields.textEu)}
else if(window.localStorage.lang === 'es-ES'){text = marked(info.fields.textEs)}
return <div className="content">
  <Row>
    <Col md={8}><TitleBar title={<Translator path="About" />} />
    <div style={{textAlign:'justify'}} dangerouslySetInnerHTML={{__html: text}} />

</Col>
    <Col md={4}><img style={{width:'100%'}} src={info.fields.image.fields.file.url} alt=""/>
</Col>
  </Row>

<div >

</div>

</div>

})}      
    </div>
  );
};

export default Biog;
