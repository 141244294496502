export default {
  translations: {
      

    About: 'Nitaz',
    Stories: 'Ipuin Kontaketak',
    Schools: 'Eskolak',
    Cards: 'Denda',
    Workshops: 'Tailerrak',
    Meditation: 'Meditazioa',
    Blog: 'Blog',
    Contact: 'Kontaktua',
    Subtitle: 'Yoga, Ipuinak eta Meditazioa',
    
    Login: 'sartu',
    BuyHere:'Klik egin eta erosi',
    IncludingShipping: 'gastuak barne',
    FormText1: 'Eskaera-orria bete.',
    FormText2: 'Zuzenean Bizum bidez ordain dezakezu, edo, nahiago baduzu banku-transferentzia bidez. ',
    FormText3: 'Banku-transferentzia bidez ordaindu hani izanez gero, banku-datuak posta elektronikoz jasoko dituzu.',
    Name: 'Izen-abizenak',
    Email: 'posta elektronikoa',
    Address: 'Posta-helbidea',
    BizumPayment: 'Oraintxe ordaindu dut Bizum bidez 0690 630 748ra',
    BankPayment: 'Transferentzia bidez ordaindu nahi dut. Bidali, mesedez, bankuko datuak e-postaz.',
    Submit: 'Bidali',
    Close: 'Itxi',
    ReadMore: 'Gehiago',
    SignUp:' Izena eman',
    Classes1:'ORDUTEGIA',
    Classes2:'Donostiako Egia auzoan (Tejeria kalea, 28) ematen ditut yoga eskolak.',
    ReadMore: 'gehiago',
    ContactUs: 'Idatzi',
    Share: 'Partekatu orrialde hau zure sare sozialetan',
    Login: 'Yoga Kluba: SARTU',
    YogaClub:'Yoga Kluba',
    Back: 'Itzuli',
    SignInError: 'Posta elektronikoa edo pasahitza ez da existitzen. Saiatu berriro',
    MembersArea: 'Yoga Kluba: Kideen Lekua ',
    YogaClubVideos: 'Yoga Kluba: Bideoak',




  
  }
        
  
}