import React, { useState, useEffect } from 'react';
import Main from './components/Main';
import Contact from './components/Contact';
import Footer from './components/Footer'
import Toolbar from './components/Toolbar/Toolbar'
import SideDrawer from './components/SideDrawer/SideDrawer'
import Backdrop from './components/Backdrop/Backdrop'
import SinglePost from './components/SinglePost'
import Biog from './components/Biog'
import {BrowserRouter as Router, Switch, Route, useHistory  } from 'react-router-dom';

import Kartak from './components/Kartak';
import Cuentos from './components/Cuentos';
import CuentosFull from './components/CuentosFull';
import Blog from './components/Blog';
import YogaClub from './components/yogaclub/YogaClub';
import fire from './components/signin/Fire'
import Auth from './components/signin/Auth'
import ClubMemberPage from './components/yogaclub/ClubMemberPage';
import ClubMemberPosts from './components/yogaclub/ClubPosts';
import NotFound from './components/NotFound';
import Taller from './components/Taller';
import Classes from './components/Classes';
import MeditationPage from './components/Meditation';
import { Toast } from 'react-bootstrap';

const App = () => {

const [sideDrawerOpen, setSideDrawerOpen] = useState(false)
const [email, setEmail] = useState('')
const [password, setPassword] = useState('')
const [user, setUser] = useState(null)
const [openModal, setOpenModal] = useState(false)
const [signinError, setSigninError] = useState(false)

const history = useHistory()

const drawerToggleClickHandler = () => {
   {!sideDrawerOpen ? setSideDrawerOpen(true) : setSideDrawerOpen(false)}
    
  };

   const backdropClickHandler = () => {
setSideDrawerOpen(false)  }

  const closeModal = () => {
setOpenModal(false)
setSigninError(false)
  }

  const openModalHandler = () => {
setOpenModal(true)
  backdropClickHandler()
  }

 const inputEmailHandler = e => {
setEmail(e.target.value)  }

 const inputPasswordHandler = e => {
setPassword(e.target.value)  }

 const authListener = () => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
setUser(user)      
} else {
setUser(null)      
}
    });
  }

 const signOut = () => {
  setUser(null)      
    fire.auth().signOut()
    history.push('/yogaclub')    

  }

   const signIn = (e) => {
    e.preventDefault();
 
   fire.auth().signInWithEmailAndPassword(email, password)
   .then((user) => {
     // Signed in 
setOpenModal(false)  
history.push('/clubmemberspage')    
})
 
   .catch((error) => {
     var errorCode = error.code;
     var errorMessage = error.message;
     setSigninError(true);
     setEmail('')
     setPassword('')
   });
 }



useEffect(() => {
 authListener()

},[])


 
    let backdrop;

    if(sideDrawerOpen){
      backdrop = <Backdrop click={backdropClickHandler}/>
    }
   
    let routes;
    if(user){
routes = (
  <Switch>
    <Route exact path="/" component={Main}/>
    <Route path="/contact" component={Contact}/>
    <Route path="/cards" component={Kartak}/>
    <Route path="/posts/:id" component={SinglePost}/>
    <Route path="/biography" component={Biog}/>
    <Route path="/cuentos/:id" component={CuentosFull}/>
    <Route path="/cuentos" component={Cuentos}/>
    <Route path="/workshops" component={Taller}/>
    <Route path="/classes" component={Classes}/>
    <Route path="/blog" component={Blog}/>
    <Route path="/yogaclub" component={YogaClub}/>
    <Route path="/clubmemberspage" component={ClubMemberPage}/>
    <Route path="/clubposts" component={ClubMemberPosts}/>
    <Route path="/meditation" component={MeditationPage}/>

    <Route component={NotFound}/>

    </Switch>
)
    } else {
      routes = (
        <Switch>
        <Route exact path="/" component={Main}/>
        <Route path="/contact" component={Contact}/>
        <Route path="/cards" component={Kartak}/>
        <Route path="/posts/:id" component={SinglePost}/>
        <Route path="/biography" component={Biog}/>
        <Route path="/classes" component={Classes}/>
        <Route path="/workshops" component={Taller}/>
        <Route path="/cuentos/:id" component={CuentosFull}/>
        <Route path="/cuentos" component={Cuentos}/>
        <Route path="/blog" component={Blog}/>
        <Route path="/yogaclub" component={YogaClub}/>
        <Route path="/meditation" component={MeditationPage}/>
        <Route component={NotFound}/>

      
        </Switch>

      )
    }


  return (
    // <Router>
  <div style={{height: '100%'}}>
      <Toolbar signOut={signOut} user={user} signIn={openModalHandler}  drawerClickHandler={drawerToggleClickHandler} />
      <SideDrawer user={user} openModal={openModal} show={sideDrawerOpen} click={drawerToggleClickHandler}/>
      {backdrop}

      <main style={{marginTop:'56px'}}>
      </main>   
        <Auth password={password} email={email} signinError={signinError} closeModal={closeModal} openModal={openModal} signout={signOut} submit={signIn} handlePassword={inputPasswordHandler}  handleEmail={inputEmailHandler}  />

       
    {routes}
   
    <Footer/>

    </div>
    // </Router>
  );
}


export default App;
