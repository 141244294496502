import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Confetti from 'react-confetti';
import Translator from '../i18n/Translator';
import {getYogaClubMembersPage, getClubPosts} from '../../contentful';
import { Row, Col } from 'react-bootstrap';
import marked from 'marked';

const ClubMemberPage = () => {
    const [posts, setPosts] = useState([])
    const [content, setContent] = useState([])

    useEffect(() => {
        getClubPosts().then(data => setPosts(data))
        getYogaClubMembersPage().then(data => setContent(data))
    })

    return( 
    <div className="content">
        {/* <Confetti
        numberOfPieces={100}
        recycle={false}
        /> */}
    <h2><Translator path="MembersArea"/></h2>
    {content.map(info => {
        let text;
        if(!window.localStorage.lang){text = marked(info.fields.textEs) }
        if(window.localStorage.lang === 'es-ES'){text = marked(info.fields.textEs) }
        if(window.localStorage.lang === 'eu-EU'){text = marked(info.fields.textEu) }
        return(
            
        <Row>
            <Col>
            <div style={{height:'100%' ,display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
            <section dangerouslySetInnerHTML={{__html: text}} />
            <div className="text-right">
<Link  className="btn btn-warning" to="/clubposts">videos</Link>
</div>
</div>
            </Col>
            <Col>
            <img src={info.fields.mainImage.fields.file.url} style={{width:'100%'}} />
            </Col>
        </Row>
        )})}


<div style={{marginTop: '20px'}}>
{posts.map(post => {
let text;
let title;
if(!window.localStorage.lang && post.fields.textEs)
{text = marked(post.fields.textEs) ;
title = post.fields.titleEs }
if(!window.localStorage.lang && !post.fields.textEs){
    text = marked(post.fields.textEu) 
    title = post.fields.titleEu
}
if(window.localStorage.lang === 'es-ES' && post.fields.textEs){
    text = marked(post.fields.textEs) 
    title = post.fields.titleEs
}
if(window.localStorage.lang === 'eu-EU' && post.fields.textEu){
    text = marked(post.fields.textEu) 
    title = post.fields.titleEu
    }
if(window.localStorage.lang === 'eu-EU' && !post.fields.textEu){
    text = marked(post.fields.textEs) 
    title = post.fields.titleEs
}
if(window.localStorage.lang === 'es-ES' && !post.fields.textEs){
    text = marked(post.fields.textEu) 
    title = post.fields.titleEu
}

return(
    <div className="project-card">
<Row>
    <Col md={8}>
<h4>{title}</h4>
<section dangerouslySetInnerHTML={{__html: text}} />
    </Col>
    <Col md={4}>
        {post.fields.mainImage && <img src={post.fields.mainImage.fields.file.url} style={{width:'100%'}} />}
    </Col>
</Row>


    </div>
)

})}
</div>

    </div>
    )
}

export default ClubMemberPage;