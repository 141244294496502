import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { getKartak } from '../../contentful'


const ImageGrid = (props) => {

const [images, setImages] = useState(null)

useEffect(() => {
    getKartak().then(data => setImages(data)
    )
}, [])

  return (
    <div className="img-grid">
      {images && images.map(image => (
        <div>
        <motion.div className="img-wrap" key={image.url} 
          layout
          whileHover={{ opacity: 1 }}s
          onClick={() => { 
            props.setSelectedImg(image.fields.image.fields.file.url)
        }
        }
        >
          <motion.img src={image.fields.image.fields.file.url} alt="uploaded pic"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
          />
        </motion.div>
        {/* {image.fields.title && <motion.p style={{color:'whitesmoke'}} initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }} >{image.fields.title}</motion.p>} */}
        </div>
      ))}
    </div>
  )
}

export default ImageGrid;