import React, {useState, useEffect} from 'react';
import marked from 'marked';
import TitleBar from './TitleBar';
import Translator from './i18n/Translator';
import {getMeditationPage} from '../contentful';
import {Row, Col} from 'react-bootstrap';

const MeditationPage = () => {
const [content, setContent] = useState([]);


useEffect(() => {
getMeditationPage().then(data => setContent(data))
}, [])



    return(
        <div className="content">
            {content.map(content => {
                let text;
                if(!window.localStorage.lang){text = marked(content.fields.textEs)}
                if(window.localStorage.lang === 'es-ES'){text = marked(content.fields.textEs)}
                if(window.localStorage.lang === 'eu-EU'){text = marked(content.fields.textEu)}

                return(
                    <div>
          {content.fields.mainImage &&  <img src={content.fields.mainImage.fields.file.url} style={{width:'30%'}}/>}
                    <TitleBar title={<Translator path="Meditation"/>} />
                    <div className="markdown" dangerouslySetInnerHTML={{__html: text}}/>



                    </div>
                )
           })}
        </div>
        )
}

export default MeditationPage;