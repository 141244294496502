import React, { useState, useEffect } from "react";
import { getPosts } from "../contentful";
import TitleBar from "./TitleBar";
import Translator from "./i18n/Translator";
import maite from "../assets/imgs/MAITEblog2.jpg";
import { Row, Col } from "react-bootstrap";
import marked from "marked";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    getPosts().then((data) => setPosts(data));
  }, []);

  return (
    <div>
      <div>
        <Row>
          <Col>
            {" "}
            <TitleBar title={<Translator path="Blog" />} />
          </Col>
          <Col>
            <img style={{ width: "100%" }} src={maite} />
          </Col>
        </Row>
      </div>

      {posts.map((post) => {
         const text = marked(post.fields.content)

        return (
          <div className="blog-item">
            <Row>
              <Col md={4}>
              <h4>{post.fields.title}</h4>

                {post.fields.image &&
                  <img
                    src={post.fields.image.fields.file.url}
                    style={{ width: "100%" }}
                  />
                }
              </Col>
              <Col md={8}>
                <div dangerouslySetInnerHTML={{__html: text}}/>
                { post.fields.video &&
             <video style={{width:'50%'}} className="video" controls>
            <source  src={post.fields.video.fields.file.url} type="video/mp4"/>
                Your browser does not support the video tag
                </video> }
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default Blog;
