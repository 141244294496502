import React, { useEffect, useState } from "react";
import { getKartakPage } from "../contentful";
import ImageGrid from "./imageGrid/ImageGrid";
import ImageModal from "./imageGrid/Modal";
import video from "../assets/Kartak_video_smallest.mov";
import TitleBar from "./TitleBar";
import Translator from "./i18n/Translator";
import { Row, Col } from "react-bootstrap";
import marked from "marked";
import Modal from "react-modal";
import bizum from "../assets/imgs/bizum.svg";
import logo from "../assets/logo.png";

const Kartak = () => {
  const [pageContent, setPageContent] = useState([]);
  const [selectedImg, setSelectedImg] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getKartakPage().then((data) => setPageContent(data));
  }, []);

  return (
    <div className="content">
      <TitleBar title={<Translator path="Cards" />} />

      <video style={{ width: "100%" }} className="video" controls>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag
      </video>

      <div className="cards-purchase-text">
        <Row>
          <Col xs={4}>
          
        <div className="text-center price" onClick={() => setModalOpen(true)}>
          <h4>22€</h4>
          <small>
            <Translator path="IncludingShipping" />
          </small>
        </div>
     
        </Col>
        <Col xs={4}>
        <div className="text-center">
          <p className="btn btn-success" onClick={() => setModalOpen(true)}>
            <Translator path="BuyHere" />
          </p>
        </div>
        </Col>
        <Col xs={4}>
        <div className="text-right" style={{padding:2}}>
        <img style={{ width: "100px" }} src={bizum} />
        </div>
        </Col>
        </Row>
        </div>

        <Modal isOpen={modalOpen}>
          <div className="form-content text-center">
            <img src={logo} style={{ width: "70px", marginBottom: 25 }} />

            <p>
              {" "}
              <Translator path="FormText1" />
            </p>
            <p>
              {" "}
              <Translator path="FormText2" />
            </p>
            <p>
              {" "}
              <Translator path="FormText3" />
            </p>

            <form
              className="form-group form"
              action="https://formspree.io/f/mwkwnvqy"
              method="POST"
            >
              <Translator path="Name" />
              <input
                className="form-control"
                type="text"
                name="name"
                required
              />
              <Translator path="Email" />
              <input
                className="form-control"
                type="email"
                name="_replyto"
                required
              />
              <Translator path="Address" />
              <textarea
                className="form-control"
                rows="6"
                type="text"
                name="address"
                required
              />
              <div className="text-center">
                <img style={{ width: "20%" }} src={bizum} />
                <br></br>
                <Translator path="BizumPayment" />
                <input
                  className="form-control"
                  type="radio"
                  name="payment"
                  value="bizum"
                  required
                />
                <br></br>
                <Translator path="BankPayment" />
                <input
                  className="form-control"
                  type="radio"
                  name="payment"
                  value="transferencia"
                />
                <button className="btn btn-success" type="submit">
                  <Translator path="Submit" />
                </button>
                <button
                  className="btn btn-warning"
                  onClick={() => setModalOpen(false)}
                  type="button"
                >
                  <Translator path="Close" />
                </button>
              </div>
            </form>
          </div>
        </Modal>

          <div>
            {pageContent.map((info) => {
              let text;
              if (!window.localStorage.lang) {
                text = marked(info.fields.mainTextEs);
              } else if (window.localStorage.lang === "eu-EU") {
                text = marked(info.fields.mainText);
              } else if (window.localStorage.lang === "es-ES") {
                text = marked(info.fields.mainTextEs);
              }

              return (
                <>
                <Row>
                <Col md={9}>

                  <div dangerouslySetInnerHTML={{ __html: text }} />
                  <ImageGrid setSelectedImg={setSelectedImg} />
                  {selectedImg && (
                    <ImageModal
                      selectedImg={selectedImg}
                      setSelectedImg={setSelectedImg}
                    />)}
                  </Col>
                  <Col md={3}>
                  <Row>
                    {info.fields.gallery &&
                      info.fields.gallery.map((img) => (
                        <Col xs={6} sm={6} md={12}>
                          <img
                            src={img.fields.file.url}
                            style={{ width: "100%", marginBottom: "4px" }}
                          /> 
                          </Col>))}
                  </Row>
                  </Col>
                  </Row>
                </>
              );
            })}
           
          </div>
       
    </div>
  );
};

export default Kartak;
