import React, {useState, useEffect} from 'react';
import {useParams, Link} from 'react-router-dom';
import TitleBar from './TitleBar';
import Translator from './i18n/Translator';
import {getSingleCuento} from '../contentful';
import marked from 'marked'
import { Row, Col } from 'react-bootstrap';

const CuentosFull = () => {

    const [cuento, setCuento] = useState([])
const {id} = useParams()
    useEffect(() => {
    getSingleCuento(id).then(data => setCuento(data))
    }, [])

return (
    <div>
    {cuento.map(info => {
let title;
if(!window.localStorage.lang){title = info.fields.titleEs}
else if(window.localStorage.lang === 'eu-EU'){title = info.fields.title}
else if(window.localStorage.lang === 'es-ES'){title = info.fields.titleEs}
let sub;
if(!window.localStorage.lang){sub = info.fields.subtitleEs}
else if(window.localStorage.lang === 'eu-EU'){sub = info.fields.subtitle}
else if(window.localStorage.lang === 'es-ES'){sub = info.fields.subtitleEs}
let text;
if(!window.localStorage.lang){text = marked(info.fields.textEs)}
else if(window.localStorage.lang === 'eu-EU'){text = marked(info.fields.textEu)}
else if(window.localStorage.lang === 'es-ES'){text = marked(info.fields.textEs)}

let video;
if(info.fields.youTube){video = marked(info.fields.youTube)}

return (
    <div className="content">
<Row>
    <Col md={8}>   
    <h2>{title}</h2>
<h3>{sub}</h3>
<section dangerouslySetInnerHTML={{__html: text}} />

</Col>
    <Col md={4}>
    <img src={info.fields.mainImage.fields.file.url} style={{width:'100%'}}/></Col>
</Row>
<div style={{width:'90%',margin:'20px auto'}} dangerouslySetInnerHTML={{__html: video}} />

<hr></hr>
<div style={{marginTop: 20, marginBottom:20}}>
 <Row>
{info.fields.gallery && info.fields.gallery.map(img => 
<Col md={4}>
    <img style={{width: '100%'}} src={img.fields.file.url}/>
</Col>
    )}
    </Row>
    </div>
    <Link className="btn btn-primary" to="/cuentos" ><Translator path="Back"/></Link>
    </div>
)

    })}    
    </div>
)
}

export default CuentosFull;