import React, { useState } from "react";
import Modal from "react-modal";
import { FaTimesCircle } from "react-icons/fa";
import logo from '../../assets/logo.png';
import Translator from "../i18n/Translator";

const customStyles = {
  content : {
    top: 95,
    bottom                : 'auto',
    backgroundColor: 'rgb(138, 171, 140)'
  //   overlay: { backgroundColor: "rgba(0, 0, 0, .5)"
  // }
}
}

const Auth = (props) => {

 
  
  return (
    <>
      <Modal
        isOpen={props.openModal}
        closeTimeoutMS={200}
        style={customStyles}
      >
        <div >
          <div className="close-cross">
            <FaTimesCircle id="cross" size={20} onClick={props.closeModal} />
          </div>
<div style={{width:'70%', margin:'auto'}}>
  <img src={logo} style={{width:'70px', marginBottom:25}} />
  <h3>Yoga Club</h3>
          <form onSubmit={props.submit}>
            Email
            <input
              onChange={props.handleEmail}
              type="email"
              name="email"
              className="form-control"
              value={props.email}
            />
            <Translator path="Password"/>
            <input
              onChange={props.handlePassword}
              type="password"
              name="password"
              className="form-control"
              value={props.password}
            />
            <button style={{marginTop:'25px'}} className="btn btn-success" type="submit">
              <Translator path="Login"/>
            </button>
          </form>
          {props.signinError && <p className="sign-in-error-message"><Translator path="SignInError" /></p>}
          </div>
        </div>
      </Modal>
      </>
  );
};

export default Auth;
